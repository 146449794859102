//https://github.com/Hacker0x01/react-datepicker/issues/624
@import "~@blueprintjs/core/lib/scss/variables";

.bp4-popover-wrapper.fullwidth,
.bp4-popover-wrapper.fullwidth > .bp4-popover-target {
  display: block;
  max-width: 500px;
}

.draggableBlockRowSwitch.bp4-switch {
  input:checked {
    &:disabled ~ .bp4-control-indicator {
      background: rgba($green3, 0.5);
    }

    ~ .bp4-control-indicator {
      background: $green3;

      &:hover {
        background: $green3;
      }
    }

    &:not(:disabled):active ~ .bp4-control-indicator {
      background: $green3;
    }
  }

  &:hover input:checked ~ .bp4-control-indicator {
    background: $green3;
  }

  input:checked:disabled ~ .bp4-control-indicator {
    background: rgba($green3, 0.5);
  }
}

.greenCrumb {
  color: $green4 !important;
}

.blueCrumb {
  color: $blue3 !important;
}

.clone-node-select {
  width: 100%;

  .bp4-popover-target {
    width: 100%;
  }
}

.month-year-nav + div {
  display: inline-grid;
  grid-template-columns: repeat(3, 1fr);
}

.years-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.quarter-year-nav + div {
  display: inline-grid;
  grid-template-columns: repeat(4, 1fr);
}

.bp4-popover2-content {
  .bp4-button {
    .bp4-button-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      // Add padding to prevent descenders from being clipped by
      // `overflow: hidden;` when rendered with the `Segoe UI` font (the default
      // on windows) which overflows the default line-height on the button.
      //
      // 2px appears to be sufficient and fits in the slack on all button sizes.
      // If more padding is required we'll need to make it button size specific.
      padding: 2px 0;
    }

    height: 30px;

    &.bp4-large,
    .bp4-large & {
      height: 40px;
    }
    &.bp4-small,
    .bp4-small &,
    .bp4-input-action & {
      height: 24px;
    }

    .bp4-button-spinner {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
}

.bp4-control .bp4-control-indicator {
  background-color: $white;
  &:hover {
    background-color: $white;
  }
}

.bp4-control .bp4-checkbox {
  &:hover {
    .bp4-control-indicator {
      background-color: $white;
    }
  }
}

.d-flex {
  display: flex !important;
}

.justify-content-center {
  justify-content: center !important;
}

.align-items-center {
  align-items: center !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.comment-popover {
  position: absolute;
  top: 0;
  right: 0;
}

.comment-popover-cn {
  width: 30vw;
}
